<template>
  <div>
    <material-card
      color="general"
      :title="$t('Vehicle.title')"
      :text="$t('Vehicle.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t('Common.new_item') }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-car-estate
            </v-icon>
            {{ $t('Vehicle.subtitle') }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="editVehicleForm"
              v-model="editedItem.isValid"
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.vehicle_type"
                      :label="$t('Vehicle.vehicle_type')"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      v-model="editedItem.vehicle_manufacturer"
                      :label="$t('Vehicle.vehicle_manufacturer')"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      v-model="editedItem.vehicle_model"
                      :label="$t('Vehicle.vehicle_model')"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      v-model="editedItem.vehicle_year_of_production"
                      :label="$t('Vehicle.year_of_production')"
                      :rules="[rules.required, rules.isNumber, rules.length4]"
                    />
                    <v-text-field
                      v-model="editedItem.vehicle_registration"
                      :label="$t('Vehicle.vehicle_registration')"
                      :rules="[rules.required]"
                    />
                    <v-menu
                      v-model="menuDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItemFormatedDeliveryDate"
                          :label="$t('Vehicle.registration_expires')"
                          :error-messages="datePickerErrors"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="editedItem.vehicle_registration_valid_to"
                        :dark="true"
                        @input="validateDate"
                      />
                    </v-menu>
                    <v-textarea
                      v-model="editedItem.vehicle_note"
                      :label="$t('Vehicle.note_label')"
                      :hint="$t('Vehicle.note_example')"
                    />
                    <v-flex v-if="role === 'SUPER_ADMIN'">
                      <v-select
                        v-model="editedItem.company_id"
                        :items="sortedCompanyList"
                        item-text="company_name"
                        item-value="id"
                        menu-props="auto"
                        :label="$t('Common.company')"
                        @change="changeEditedItemCompany"
                      />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <v-alert
              v-if="alertTextEdit"
              dense
              type="error"
            >
              {{ alertTextEdit }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t('Common.cancel') }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :disabled="!editedItem.isValid"
              :loading="isSaving"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t('Common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="vehicleList"
          :options.sync="paginationOptions"
          :server-items-length="(vehicleListMeta?.itemCount) ? vehicleListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('Common.tooltips.edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t('Common.tooltips.delete') }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="value => isDeleteModalVisible = value"
      @confirm-btn-click="() => deleteItem()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultPopup from '../../components/DefaultPopup.vue';
import validationMixin from '../../components/helper/validationMixin';

export default {
  components: {
    DefaultPopup,
  },

  mixins: [validationMixin],

  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      vehicle_type: '',
      vehicle_manufacturer: '',
      vehicle_model: '',
      vehicle_year_of_production: '',
      vehicle_registration: '',
      vehicle_registration_valid_to: '',
      vehicle_note: '',
      isValid: true,
      company_id: "",
    },
    defaultItem: {
      vehicle_type: '',
      vehicle_manufacturer: '',
      vehicle_model: '',
      vehicle_year_of_production: '',
      vehicle_registration: '',
      vehicle_registration_valid_to: '',
      vehicle_note: '',
      company_id: "",
    },
    menuDatePicker: false,
    datePickerErrors: [],
    role: "",
    alertTextEdit: '',
    alertTextDelete: '',
    isSaving: false,
     // table:
     paginationCount: 0,
    paginationLoading: false,
    paginationOptions: {},
    sortedCompanyList: null,
  }),

  computed: {
    ...mapGetters('vehicles', ['vehicleList', 'vehicleListMeta']),
    ...mapGetters("company", ["companyList"]),

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    headers() {
      const headers =  [
        { text: this.$t('Vehicle.vehicle_registration'), value: 'vehicle_registration' },
        { text: this.$t('Vehicle.vehicle_manufacturer'), value: 'vehicle_manufacturer' },
        { text: this.$t('Vehicle.vehicle_model'), value: 'vehicle_model' },
        { text: this.$t('Vehicle.vehicle_type'), value: 'vehicle_type' },
        { text: this.$t('Common.note'), value: 'vehicle_note' },
        { text: this.$t('Common.actions'), value: 'actions',  sortable: false }
      ]

      if(this.role === "SUPER_ADMIN") {
        headers.splice(5, 0,  { text: this.$t("Common.company"), value: "company.company_name" });
      }

      return headers;
    },
    editedItemFormatedDeliveryDate() {
      return this.formatDate(this.editedItem.vehicle_registration_valid_to)
    }
  },

  watch: {
    dialog(val) {
      this.alertTextEdit = ''
      if (val) {
        this.validateDate();
        this.$nextTick(() => {this.$refs.editVehicleForm.validate()});
      }
      else this.close();
    },
    paginationOptions: {
      handler() {
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  // lifecycle hooks
  beforeDestroy() {
    this.clearVehicleState();
  },

  methods: {
    ...mapActions('vehicles', [
      'getVehicleList',
      'createVehicle',
      'updateVehicle',
      'deleteVehicle',
      'clearVehicleState',
    ]),
    ...mapActions("company", ["getCompanyList"]),

    async initialize() {
      this.paginationLoading = true;
      this.role = sessionStorage.getItem("role");
      this.paginationOptions.role = sessionStorage.getItem("role");
      await Promise.all([
        this.getVehicleList(this.paginationOptions),
        this.getCompanyList({ itemsPerPage: 'all', role: this.role }),
      ])
      this.sortCompanyList(),
      this.paginationLoading = false;
    },

    formatDate(value) {
      return value ? new Date(value).toLocaleDateString() : '';
    },

    editItem(item) {
      this.editedIndex = this.vehicleList.indexOf(item)
      this.editedItem = Object.assign({}, item, { company_id: item?.company?.id || null })
      this.editedItem.vehicle_registration_valid_to = new Date(this.editedItem.vehicle_registration_valid_to).toISOString().split('T', 1)[0];
      this.dialog = true
    },

    showDeleteModal(item) {
      this.alertTextDelete = ''
      this.isDeleteModalVisible = true
      this.deleteModalItemId = item?.id
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteVehicle(this.deleteModalItemId)
        if (isDeleted) {
          this.isDeleteModalVisible = false
        } else {
          this.alertTextDelete = this.$t('Vehicle.alerts.error-delete')
        }
      }
    },

    close() {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    async save() {
      this.isSaving = true
      this.alertTextEdit = ''
      if (this.editedIndex > -1) {
        const isUpdated = await this.updateVehicle(this.editedItem);
        if (isUpdated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t('Vehicle.alerts.error-edit')
        }
      } else {
        const isCreated = await this.createVehicle(this.editedItem);
        if (isCreated) {
          this.close()
        } else {
          this.alertTextEdit = this.$t('Vehicle.alerts.error-create')
        }
      }
      this.isSaving = false
    },

    validateDate() {
      const validationResult = this.rules.required(this.editedItem.vehicle_registration_valid_to);
      if (validationResult !== true) {
        this.datePickerErrors = [validationResult];
      } else {
        this.datePickerErrors = [];
      }
    },

    sortCompanyList() {
      this.sortedCompanyList = this.companyList.sort((a, b) =>
        a.company_name.localeCompare(b.company_name)
      );
    },

    changeEditedItemCompany(id) {
      this.editedItem.company = this.sortedCompanyList.find(c => c.id === id)
    },
  }
}
</script>
